define("zipbooks/routes/main/time-and-pay", ["exports", "zipbooks/mixins/partial-page-refresh", "zipbooks/mixins/refreshable-route", "zipbooks/mixins/permission-redirect"], function (_exports, _partialPageRefresh, _refreshableRoute, _permissionRedirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_partialPageRefresh.default, _refreshableRoute.default, _permissionRedirect.default, {
    client: Ember.inject.service(),
    overlay: Ember.inject.service(),
    queryParams: {
      user_ids: {},
      pay_period: {}
    },
    model: function model(_params) {
      var _this = this;

      return Ember.RSVP.hash({
        payPeriods: this.client.GET("payroll/pay-periods"),
        users: this.store.query('user', {
          include: 'identity.users.account.logo_cloud_file,identity.avatar_cloud_file,integration_object',
          sort: 'display_name'
        }),
        gusto: this.client.GET('integrations/gusto').then(function (resp) {
          _this.store.pushPayload('gusto-integration', resp);

          return _this.store.peekRecord('gusto-integration', resp.data.id);
        }, function () {})
      });
    },
    afterModel: function afterModel(model) {
      if (model.gusto && (!model.gusto.externalCompanyUuid || !model.gusto.finishedOnboarding)) {
        this.overlay.showDialog('overlays/dialogs/gusto-onboarding', 'Gusto Setup', {
          customHeader: true,
          gustoModel: model.gusto
        });
      }

      this._super.apply(this, arguments);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.incrementProperty('_invalidations');

      if (controller.summaryModel.isPending) {
        controller.set('isRefreshing', true);
      }

      if (!controller.pay_period) {
        controller.set('pay_period', model.payPeriods.lastObject.period);
      }
    }
  });

  _exports.default = _default;
});