define("zipbooks/models/gusto-integration", ["exports", "@ember-data/model", "zipbooks/models/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    enableSync: (0, _model.attr)('boolean'),
    externalCompanyId: (0, _model.attr)('string'),
    externalCompanyUuid: (0, _model.attr)('string'),
    finishedOnboarding: (0, _model.attr)('boolean'),
    hasNewUsers: (0, _model.attr)('boolean'),
    syncedTo: (0, _model.attr)()
  });

  _exports.default = _default;
});